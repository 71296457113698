// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Poppins:100,200,300,400,500,600,700,800,900&display=swap');
@import '../../../node_modules/bootstrap/scss/functions';
@import 'theme/functions';
@import 'user-variables';
@import 'theme/variables';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/bootstrap';

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
@import 'theme/theme';

/*-----------------------------------------------
|   User Styles
-----------------------------------------------*/
@import 'user';
